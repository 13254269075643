<template>
  <div v-if="orderTotals" class="grid-container text-center">
    <div class="">
      <span class="text-muted">Discount</span>
      <div>
        {{ formatCurrency(orderTotals.discount) }}
      </div>
    </div>
    <div class="">
      <span class="text-muted">Shipping</span>
      <div>
        {{ formatCurrency(orderTotals.shipping) }}
      </div>
    </div>
    <div class="">
      <span class="text-muted">Tax</span>
      <div>
        {{ formatCurrency(orderTotals.tax) }}
      </div>
    </div>
    <div class="">
      <span class="text-muted">Subtotal</span>
      <div>
        {{ formatCurrency(orderTotals.subtotal) }}
      </div>
    </div>
    <div class="">
      <span class="text-muted">Total</span>
      <h5 class="text-warning">
        {{ formatCurrency(orderTotals.total) }}
      </h5>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { currencyFormatter } from '@core/utils/filter'
import useFbmState from '../useFbmStates'

export default {
  setup() {
    const { currentOrder } = useFbmState()
    const orderTotals = computed(() => currentOrder.orderTotals)
    return {
      orderTotals,
      currentOrder,
    }
  },
  methods: {
    formatCurrency(v) {
      return currencyFormatter(v)
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
}

.text-center {
  text-align: center;
}
</style>
